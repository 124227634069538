import React from "react"
import Waverton from "../images/LandCWavertonlogo.png"
import Vespa from "../images/VespaCapital.png"

import pam from "../images/paminsight.jpg"
import sportData from "../images/sportsdata.jpg"

interface FooterProps {
  charities: any
  includeSponsers?: boolean
}

export const Footer: React.FC<FooterProps> = ({
  charities,
  includeSponsers = false,
}) => {
  return (
    <footer className="py-5 my-8">
      <div className="border-t border-b py-3 px-5 md:px-0">
        <div className="container mx-auto">
          <ul className="grid grid-cols-1 md:grid-cols-4">
            {charities.map(charity => (
              <li className="text-center">
                <img className="inline" src={charity.logo} alt={charity.name} />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {includeSponsers && (
        <div className="border-t border-b py-10 px-5 md:px-0">
          <div className="container mx-auto">
            <ul className="flex justify-center">
              <li className="text-center mr-20">
                <a href="https://sportsdata.io" target="_blank">
                  <img
                    className="inline"
                    width="200"
                    src="https://sportsdata.io/assets/images/badges/sportsdataio_dark_ss_300.png"
                    alt="Powered by SportsDataIO"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className="text-center mt-8 px-5 md:px-0">
        <div className="flex items-center justify-center gap-5">
          <img className="inline" src={Waverton} width="500" />
          <img className="inline relative -top-2.5" src={Vespa} />
        </div>
        <p>
          Sponsored by Waverton Investment Management Ltd.
          <br />
          <a className="text-teal" href="https://waverton.co.uk">
            www.waverton.co.uk
          </a>
        </p>
      </div>
    </footer>
  )
}
